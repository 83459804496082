import React from 'react'

const SvgGeneFeature2 = props => (
  <svg id='gene_feature2_svg__tuceng_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.gene_feature2_svg__st1{fill:#334966}.gene_feature2_svg__st2{fill:#fd5c1f}'}</style>
    <g id='gene_feature2_svg__\u7BA1\u7406\u7B80\u5355-48x48'>
      <circle id='gene_feature2_svg__Oval-7' cx={25} cy={25} r={23} fill='none' stroke='#334966' strokeWidth={2} />
      <path id='gene_feature2_svg__Rectangle-53-Copy' className='gene_feature2_svg__st1' d='M14 16h2v18h-2z' />
      <path id='gene_feature2_svg__Rectangle-53-Copy-2' className='gene_feature2_svg__st1' d='M34 16h2v18h-2z' />
      <circle id='gene_feature2_svg__Oval-20' className='gene_feature2_svg__st2' cx={15} cy={16} r={3} />
      <circle id='gene_feature2_svg__Oval-20-Copy-2' className='gene_feature2_svg__st2' cx={35} cy={16} r={3} />
      <circle id='gene_feature2_svg__Oval-20-Copy' className='gene_feature2_svg__st2' cx={15} cy={34} r={3} />
      <circle id='gene_feature2_svg__Oval-20-Copy-3' className='gene_feature2_svg__st2' cx={35} cy={34} r={3} />
      <circle id='gene_feature2_svg__Oval-20-Copy-4' className='gene_feature2_svg__st2' cx={25} cy={25} r={4} />
      <path id='gene_feature2_svg__Rectangle-53' className='gene_feature2_svg__st1' d='M24 13h2v24h-2z' />
    </g>
  </svg>
)

export default SvgGeneFeature2
