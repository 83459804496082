import React from 'react'

const SvgGeneAdvantage3 = props => (
  <svg id='gene_advantage3_svg__tuceng_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.gene_advantage3_svg__st2{fill:#334966}'}</style>
    <g id='gene_advantage3_svg__\u771F'>
      <g id='gene_advantage3_svg__\u817E\u8BAF\u4E91-\u5929\u67A2' transform='translate(-696 -858)'>
        <g id='gene_advantage3_svg__features' transform='translate(0 718)'>
          <g id='gene_advantage3_svg__features-grid' transform='translate(143 140)'>
            <g id='gene_advantage3_svg__feature' transform='translate(401)'>
              <g id='gene_advantage3_svg__features_icon_2' transform='translate(152)'>
                <path
                  id='gene_advantage3_svg__Rectangle-86_1_'
                  fill='none'
                  stroke='#334966'
                  strokeWidth={2}
                  d='M3 9.27v31.46L9.27 47h31.46L47 40.73V9.27L40.73 3H9.27z'
                />
                <path id='gene_advantage3_svg__Triangle' fill='#fd5c1f' d='M27 20l7.65 13h-15.3z' />
                <path
                  id='gene_advantage3_svg__Path-3'
                  className='gene_advantage3_svg__st2'
                  d='M27.08 12.38h2.37L18.22 31.5l-1.73-1z'
                />
                <path
                  id='gene_advantage3_svg__Path-3_1_'
                  className='gene_advantage3_svg__st2'
                  d='M26.07 21.09l1.09-1.89 10.48 18.13h-2.31z'
                />
                <path
                  id='gene_advantage3_svg__Path-3_2_'
                  className='gene_advantage3_svg__st2'
                  d='M23.88 15.1l-1.23 1.75-2.62-4.45h2.15z'
                />
                <path
                  id='gene_advantage3_svg__Path-3_3_'
                  className='gene_advantage3_svg__st2'
                  d='M14.67 37.59h-2.06l2.74-4.8h2.17z'
                />
                <path
                  id='gene_advantage3_svg__Path-4'
                  className='gene_advantage3_svg__st2'
                  d='M29.72 30l1.09 2h-18.2v-2z'
                />
                <path
                  id='gene_advantage3_svg__Path-4_1_'
                  className='gene_advantage3_svg__st2'
                  d='M38 30v2h-2.2l-1.23-2z'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgGeneAdvantage3
