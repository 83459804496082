import React from 'react'
import { graphql } from 'gatsby'
import intl from 'react-intl-universal'
import { IndustryBanner, InviteBanner } from '../../components/banners'
import { CommonTitle } from '../../components/titles'
import { LOCALES } from '@raysync/common/services'
import { Swiper, H4 } from '@raysync/common/components'

import Layout from '../../components/layout'

import {
  SectionDesc,
  FeatureSection,
  SolutionSection,
  AdvantageSection,
  AdvantageContainer,
  GeneAdvantageItem,
  PictureWrap,
} from './atoms'
import List from '../../components/list'

import Feature1 from '@raysync/common/components/svg/solutions/industry/gene-feature1'
import Feature2 from '@raysync/common/components/svg/solutions/industry/gene-feature2'
import Feature3 from '@raysync/common/components/svg/solutions/industry/gene-feature3'
import Feature4 from '@raysync/common/components/svg/solutions/industry/gene-feature4'

import Advantage1 from '@raysync/common/components/svg/solutions/industry/gene-advantage1'
import Advantage2 from '@raysync/common/components/svg/solutions/industry/gene-advantage2'
import Advantage3 from '@raysync/common/components/svg/solutions/industry/gene-advantage3'
import Advantage4 from '@raysync/common/components/svg/solutions/industry/gene-advantage4'
import Advantage5 from '@raysync/common/components/svg/solutions/industry/gene-advantage5'

import Solution from '@raysync/common/components/svg/solutions/industry/gene-solution'
import SolutionEn from '@raysync/common/components/svg/solutions/industry/gene-solution-en'
class GeneIndustry extends React.PureComponent {
  state = {
    challengeList: [
      {
        title: intl.get('gene.challenge.item1.title'),
        desc: intl.get('gene.challenge.item1.desc'),
        svg: <Feature1 />,
      },
      {
        title: intl.get('gene.challenge.item2.title'),
        desc: intl.get('gene.challenge.item2.desc'),
        svg: <Feature2 />,
      },
      {
        title: intl.get('gene.challenge.item3.title'),
        desc: intl.get('gene.challenge.item3.desc'),
        svg: <Feature3 />,
      },
      {
        title: intl.get('gene.challenge.item4.title'),
        desc: intl.get('gene.challenge.item4.desc'),
        svg: <Feature4 />,
      },
    ],
    advantageList: [
      {
        title: intl.get('gene.advantage.item1.title'),
        desc: intl.get('gene.advantage.item1.desc'),
        svg: <Advantage1 />,
      },
      {
        title: intl.get('gene.advantage.item2.title'),
        desc: intl.get('gene.advantage.item2.desc'),
        svg: <Advantage2 />,
      },
      {
        title: intl.get('gene.advantage.item3.title'),
        desc: intl.get('gene.advantage.item3.desc'),
        svg: <Advantage3 />,
      },
      {
        title: intl.get('gene.advantage.item4.title'),
        desc: intl.get('gene.advantage.item4.desc'),
        svg: <Advantage4 />,
      },
      {
        title: intl.get('gene.advantage.item5.title'),
        desc: intl.get('gene.advantage.item5.desc'),
        svg: <Advantage5 />,
      },
    ],
  }
  render() {
    const { location, data } = this.props
    const { challengeList, advantageList } = this.state
    const solutionPic = {
      [LOCALES.ZH]: <Solution />,
      [LOCALES.EN]: <SolutionEn />,
      [LOCALES.JA]: <SolutionEn />,
    }[intl.options.currentLocale]
    return (
      <Layout pageType='gene' location={location}>
        <Swiper>
          <IndustryBanner type='gene' data={data.geneBanner.childImageSharp.fluid} />
        </Swiper>
        <FeatureSection>
          <CommonTitle bottom={40}>{intl.get('gene.challenge.title')}</CommonTitle>
          <List data={challengeList} />
        </FeatureSection>
        <SolutionSection>
          <CommonTitle>{intl.get('gene.solution.title')}</CommonTitle>
          <SectionDesc top={21} bottom={43}>
            {intl.get('gene.solution.desc')}
          </SectionDesc>
          <PictureWrap>{solutionPic}</PictureWrap>
        </SolutionSection>
        <AdvantageSection>
          <CommonTitle>{intl.get('gene.advantage.title')}</CommonTitle>
          <AdvantageContainer>
            {advantageList.map((l, i) => (
              <GeneAdvantageItem key={i}>
                <div>{l.svg}</div>
                <H4>{l.title}</H4>
                <p>{l.desc}</p>
              </GeneAdvantageItem>
            ))}
          </AdvantageContainer>
        </AdvantageSection>
        <Swiper>
          <InviteBanner type='gene' variant='inquire' />
        </Swiper>
      </Layout>
    )
  }
}

export default GeneIndustry

export const query = graphql`
  query {
    geneBanner: file(relativePath: { regex: "/industry/gene_banner/" }) {
      ...fluidImage
    }
  }
`
