import React from 'react'

const SvgGeneAdvantage5 = props => (
  <svg
    id='gene_advantage5_svg__tuceng_1'
    x={0}
    y={0}
    viewBox='0 0 50 50'
    xmlSpace='preserve'
    enableBackground='new 0 0 50 50'
    {...props}
  >
    <style>{'.gene_advantage5_svg__st0{fill:#334966}.gene_advantage5_svg__st3{fill:#fff}'}</style>
    <path
      className='gene_advantage5_svg__st0'
      d='M29.9 16.81l-5.74 5.74-1.43-1.43 4.3-4.31-4.3-4.3 1.43-1.44zM13.67 11.07l1.43 1.44-4.3 4.3 4.3 4.31-1.43 1.43-5.74-5.74z'
    />
    <path d='M15.83 22.24l4.28-11.4 1.9.71-4.28 11.4-1.9-.71zm-7.9 14.89h34.5V28H7.93v9.13z' fill='#fd5c1f' />
    <g transform='translate(24 20)'>
      <defs>
        <filter
          id='gene_advantage5_svg__Adobe_OpacityMaskFilter'
          filterUnits='userSpaceOnUse'
          x={1.88}
          y={3.08}
          width={22.94}
          height={23.99}
        >
          <feColorMatrix values='1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0' />
        </filter>
      </defs>
      <mask maskUnits='userSpaceOnUse' x={1.88} y={3.08} width={22.94} height={23.99} id='gene_advantage5_svg__adb_2_'>
        <g filter='url(#gene_advantage5_svg__Adobe_OpacityMaskFilter)'>
          <path
            id='gene_advantage5_svg__ada_2_'
            className='gene_advantage5_svg__st3'
            d='M1.88 3.08h22.94v23.99H1.88V3.08z'
          />
        </g>
      </mask>
      <path
        d='M13.3 3.08c-.84 0-1.68.08-2.51.23l-.51.1-1.12 3.12c-.19.56-.78.95-1.35.95-.07 0-.13-.01-.19-.02l-3.07-.51-.41.41C3.21 8.5 2.6 9.75 2.09 11.1l-.2.52 2.05 2.49c.41.52.41 1.35 0 1.87l-2.05 2.49.2.52c.2.73.51 1.43.92 2.07.31.62.72 1.14 1.13 1.66l.41.42 3.18-.52c.06-.01.13-.02.19-.02.56 0 1.16.39 1.34.95l1.02 3.11.61.1c1.68.41 3.44.41 5.12 0l.51-.1 1.02-3.11c.18-.56.78-.95 1.34-.95.06 0 .13.01.19.02l3.07.52.41-.52c.82-1.04 1.54-2.28 1.95-3.63l.2-.52-1.95-2.38c-.41-.52-.41-1.35 0-1.87l2.05-2.59-.2-.52c-.2-.7-.51-1.37-.92-1.97-.31-.62-.72-1.14-1.13-1.66l-.41-.41-3.17.41c-.62 0-1.33-.41-1.54-1.04l-1.02-3.01-.61-.1c-.81-.17-1.65-.25-2.5-.25m0 2.03c.53 0 1.06.04 1.59.11l.63 1.84c.46 1.41 1.92 2.43 3.47 2.43h.13l.13-.02 2.1-.27c.21.28.39.54.53.82l.06.12.08.11c.21.31.38.64.51.98l-1.35 1.71c-.99 1.25-.99 3.13.02 4.41l1.22 1.49c-.28.74-.66 1.44-1.12 2.08l-1.88-.32c-.17-.03-.35-.04-.53-.04-1.47 0-2.82.97-3.27 2.35l-.66 2c-1.04.18-2.1.18-3.13-.02l-.65-1.98c-.45-1.38-1.8-2.34-3.27-2.35-.18 0-.36.02-.52.04l-2.06.34a5.01 5.01 0 01-.5-.79l-.05-.1-.06-.09c-.22-.34-.4-.71-.54-1.1l1.35-1.64c.99-1.25.99-3.13-.03-4.41l-1.29-1.57c.35-.83.71-1.51 1.13-2.11l1.94.33c.17.04.35.05.53.05 1.46 0 2.79-.95 3.26-2.31l.72-1.99c.5-.07 1.01-.1 1.51-.1'
        mask='url(#gene_advantage5_svg__adb_2_)'
        fill='#334966'
      />
    </g>
    <path
      className='gene_advantage5_svg__st0'
      d='M37.36 31.02c-2.24 0-4.06 1.82-4.06 4.06s1.82 4.06 4.06 4.06c2.24 0 4.06-1.82 4.06-4.06s-1.82-4.06-4.06-4.06m0 2.03c1.12 0 2.03.91 2.03 2.03s-.91 2.03-2.03 2.03c-1.12 0-2.03-.91-2.03-2.03s.91-2.03 2.03-2.03'
    />
    <defs>
      <filter
        id='gene_advantage5_svg__Adobe_OpacityMaskFilter_1_'
        filterUnits='userSpaceOnUse'
        x={1.17}
        y={2.93}
        width={44}
        height={40}
      >
        <feColorMatrix values='1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={1.17} y={2.93} width={44} height={40} id='gene_advantage5_svg__add_2_'>
      <g filter='url(#gene_advantage5_svg__Adobe_OpacityMaskFilter_1_)'>
        <path id='gene_advantage5_svg__adc_2_' className='gene_advantage5_svg__st3' d='M1.15 2.9h44v40h-44v-40z' />
      </g>
    </mask>
    <path d='M24.17 40.93h-21v-36h40v20h2v-22h-44v40h23v-2z' mask='url(#gene_advantage5_svg__add_2_)' fill='#334966' />
  </svg>
)

export default SvgGeneAdvantage5
