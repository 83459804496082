import React from 'react'

const SvgGeneFeature4 = props => (
  <svg id='gene_feature4_svg__tuceng_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.gene_feature4_svg__st0{fill:#334966}.gene_feature4_svg__st1{fill:#fd5c1f}'}</style>
    <g id='gene_feature4_svg__\u591A\u7EF4\u62A5\u8868\u8FD0\u8425-48x48'>
      <path
        id='gene_feature4_svg__Rectangle-34'
        className='gene_feature4_svg__st0'
        d='M3 7v36h44V7H3zM1 5h48v40H1V5z'
      />
      <circle id='gene_feature4_svg__Oval-16' className='gene_feature4_svg__st1' cx={22.5} cy={26.5} r={9.5} />
      <path
        id='gene_feature4_svg__Combined-Shape_25_'
        d='M22 14.04V26H10.04c.48-6.38 5.58-11.48 11.96-11.96z'
        fill='none'
        stroke='#334966'
        strokeWidth={2}
      />
      <path id='gene_feature4_svg__Rectangle-35' className='gene_feature4_svg__st0' d='M37 13h4v2h-4z' />
      <path id='gene_feature4_svg__Rectangle-35-Copy' className='gene_feature4_svg__st1' d='M37 20h4v2h-4z' />
      <path id='gene_feature4_svg__Rectangle-35-Copy-2' className='gene_feature4_svg__st1' d='M37 27h4v2h-4z' />
      <path id='gene_feature4_svg__Rectangle-35-Copy-3' className='gene_feature4_svg__st0' d='M9 35h32v2H9z' />
    </g>
  </svg>
)

export default SvgGeneFeature4
