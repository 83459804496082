import styled, { css } from 'styled-components'
import { Wrapper, Divider } from '@raysync/common/components'
import { Media } from '@raysync/common/theme'

export const SectionDesc = styled.p`
  ${({ theme: { color, typography } }) => css`
    color: ${color.text};
    line-height: 24px;
    font-size: ${typography.textSmall};
    padding-top: ${p => p.top + 'px'};
    padding-bottom: ${p => p.bottom + 'px'};
    ${Media.phone`
      line-height: .5rem;
      padding-top: ${p => p.top / 100 + 'rem'};
      padding-bottom: ${p => p.bottom / 100 + 'rem'};
    `}
  `}
`

export const FeatureSection = styled(Wrapper)`
  padding: 68px 0 42px 0;
  ${Media.phone`
    padding-top: .9rem;
    padding-bottom: 0;
  `}
`

export const SolutionSection = styled(Wrapper)`
  padding: 40px 0 65px 0;
  svg {
    display: block;
    margin: 0 auto;
  }
  ${Media.phone`
    padding-top: 1rem;
    padding-bottom: 0.7rem;
  `}
`
export const AdvantageSection = styled(Wrapper)`
  padding: 40px 0 96px 0;
  ${Media.phone`
    padding-top: .6rem;
    padding-bottom: 1.35rem;
  `}
`
export const AdvantageContainer = styled.div`
  margin-top: 44px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${Media.phone`
    margin-top: .72rem;
  `}
`
export const AdvantageItem = styled.div`
  ${({ theme: { color, typography, Hack } }) => css`
    width: 270px;
    padding-bottom: 10px;
    min-height: 300px;
    box-shadow: 0px 0px 14px 0px rgba(135, 150, 170, 0.14);
    svg {
      width: 48px;
      height: 48px;
      display: block;
      margin: 40px auto 28px;
    }
    h4 {
      text-align: center;
      font-size: 20px;
    }
    p {
      padding: 0 28px;
      text-align: center;
      margin-top: 15px;
      color: ${color.text};
      line-height: 24px;
      font-size: ${typography.textSmall};
    }

    ${Hack.desktopEn`
      padding-bottom: 20px;
    `}

    ${Media.phone`
      width: 100%;
      min-height: initial;
      margin-bottom: .4rem;
      padding-bottom: .4rem;
      svg{
        width: .8rem;
        height: .8rem;
        margin: .5rem auto .3rem;
      }
      p{
        font-size: .24rem;
        padding: 0 .48rem;
        line-height: .4rem;
      }
    `}
  `}
`
export const GeneAdvantageItem = styled(AdvantageItem)`
  width: 224px;
  h4 {
    line-height: 28px;
  }

  ${p => p.theme.Hack.desktopEn`
    h4 {
      padding: 0 30px;
      font-size: 24px;
      line-height: 32px;
    }
  `}

  ${Media.phone`
    width: 100%;
    padding-bottom: .9rem;
  `}
`

export const IndustryDivider = styled(Divider)`
  ${({ theme: { color } }) => css`
    background: ${color.line};
  `}
`

export const PictureWrap = styled.div`
  margin: 0 94px;
  ${Media.phone`
    margin: 0;
  `}
`
